import {
    CrudTypes,
    formatDateTime,
    getArrayDataContent,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useCrudFetch,
    useCrudSubscription,
    useDeepCompareEffect,
    useGlobalParam,
    usePostNotification
} from "@cuda-react/core";
import {DAY_AS_MILLISECONDS} from "../utils/dateTime";
import apiResources from "../apiResources";


export default () => {
    const subscriptionActive = useGlobalParam("userData.subscription.licensed")[0];
    const [{data: updatesUnacknowledged}] = useCrudSubscription(CrudTypes.GET, subscriptionActive && apiResources.updatesUnacknowledged, {filter: {acknowledged: false}}, {
        pollInterval: DAY_AS_MILLISECONDS,
        crudOptions: {quietErrors: true}
    });
    const [, , acknowledge] = useCrudFetch(CrudTypes.CREATE, apiResources.updatesAcknowledge, {}, {quietErrors: true});
    const postNotification = usePostNotification();

    // Display all update notifications
    useDeepCompareEffect(() => {
        getArrayDataContent(updatesUnacknowledged).forEach((update: any) => {
            postNotification({
                event: NotificationEventType.SHOW,
                display: NotificationDisplayType.BANNER,
                params: {
                    content: "tesseract.statusBanner.updateAvailable",
                    dismissible: true,
                    spinner: false,
                    translateParams: {
                        name: update.name,
                        date: formatDateTime(update.mustInstallBy, "MMMM d, yyyy"),
                        id: update.id
                    },
                    level: NotificationLevel.INFO,
                    onDismiss: () => {
                        acknowledge({id: update.id});
                    }
                }
            });
        });
    }, [updatesUnacknowledged]);
};