import React, {useState} from "react";
import {MenuButton} from "@cuda-react/core";
import {Dialog} from "@barracuda-internal/bds-core";
import CreateSdwanConnectorTokenContent from "./createEdit/CreateSdwanConnectorTokenContent";

interface SdwanConnectorTableMenuProps {
    data?: {
        id?: number,
        name?: string,
    }
}

const SdwanConnectorTableMenu: React.FC<SdwanConnectorTableMenuProps> = ({data}) => {
    const [tokenDialogOpen, setTokenDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "top"}}
                transformOrigin={{horizontal: "left", vertical: "top"}}
                menuItems={[
                    {
                        label: "tesseract.network.sdwanConnector.table.ellipsisMenu.token",
                        onClick: () => setTokenDialogOpen(true)
                    },
                    {
                        label: "tesseract.network.sdwanConnector.table.ellipsisMenu.downloadWindows",
                        onClick: () => {
                            const downloadLink = window.open("https://dlportal.barracudanetworks.com/#/packages/5973/Connector_1.0.1-1_x64.exe", '_blank', 'noopener,noreferrer');
                            if (downloadLink) downloadLink.opener = null;
                        }
                    },
                    {
                        label: "tesseract.network.sdwanConnector.table.ellipsisMenu.downloadLinux",
                        onClick: () => {
                            const downloadLink = window.open("https://dlportal.barracudanetworks.com/#/packages/5974/Connector_1.0.1-1_Linux.zip", '_blank', 'noopener,noreferrer');
                            if (downloadLink) downloadLink.opener = null;
                        }
                    }
                ]}
                iconButton
            />
            <Dialog open={tokenDialogOpen} maxWidth={false} onClose={() => (setTokenDialogOpen(false))}>
                <CreateSdwanConnectorTokenContent id={data?.id} sec={data?.name}
                                                  onClose={() => (setTokenDialogOpen(false))}/>
            </Dialog>
        </React.Fragment>
    );
};

export default SdwanConnectorTableMenu;