import React from "react";
import {useTranslation} from "react-i18next";
import {Skeleton, Stepper, Step, StepLabel} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    rootLarge: {
        width: `calc(100% - ${theme.spacing(4)})`,
        minWidth: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: `calc(100% - ${theme.spacing(4)})`,
        height: `calc(100% - ${theme.spacing(2)})`,
        minHeight: `calc(100% - ${theme.spacing(2)})`,
        padding: theme.spacing(1, 2),
        display: "flex",
        "& .MuiStepIcon-text": {
            fontSize: "0.8rem",
            fontWeight: "bold"
        },
        "& .MuiStepConnector-line": {
            height: "100%"
        },
        "& .MuiStepper-root": {
            width: "100%"
        }
    },
    root: {
        width: `calc(100% - ${theme.spacing(4)})`,
        minWidth: `calc(100% - ${theme.spacing(4)})`,
        maxWidth: `calc(100% - ${theme.spacing(4)})`,
        height: `calc(100% - ${theme.spacing(2)})`,
        padding: theme.spacing(1, 2),
        display: "flex",
        "& .MuiStepConnector-lineVertical": {
            minHeight: "10px",
            marginTop: "-2px",
            marginBottom: "-4px"
        },
        "& .MuiStepConnector-root": {
            height: "8px",
            marginLeft: "10px"
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px"
        },
        "& .MuiStepIcon-text": {
            fontSize: "0.8rem",
            fontWeight: "bold"
        },
        "& .MuiStepper-root": {
            width: "100%"
        }
    },
    skeleton: {
        width: `calc(100% - ${theme.spacing(2)})`,
        height: 30,
        margin: theme.spacing(0, 1)
    }
});

const useStyles = makeOverrideableStyles("VerticalStepperCardContent", styles);

export interface step {
    /**
     * Required: The label for the step, displayed as the step's title.
     */
    label: string,
    /**
     * Optional: The state of the step (e.g., "completed", "active", "error").
     */
    state?: string,
    /**
     * Optional: An icon to be displayed alongside the step's label.
     */
    icon?: React.ReactNode,
    /**
     * Optional: A boolean indicating whether the step is in the loading state.
     */
    loading?: boolean
}

export const enum VerticalStepperCardSizes {
    small = "small",
    medium = "medium",
    large = "large",
}

export interface VerticalStepperProps extends StyledComponentProps<typeof styles> {
    /**
     * Required: An array of step objects, each representing a step in the stepper.
     */
    steps: step[],
    /**
     * Optional: The size of the card, the default is small/medium
     */
    size?: VerticalStepperCardSizes,
    /**
     * Optional: The index of the currently active step, defaults to 0
     */
    activeStep?: number,
    /**
     * Optional: A boolean indicating whether the stepper is in a loading state, when true the Skeleton component will be shown in place of the step labels.
     */
    loading?: boolean
}

export const VerticalStepperCardContent = (props: VerticalStepperProps) => {
    const {
        steps,
        size,
        activeStep,
        loading
    } = props;

    const [translate] = useTranslation();
    const classes = useStyles(props);

    return (
                <div className={size === "large" ? classes.rootLarge : classes.root}>
                    <Stepper
                        orientation="vertical"
                        activeStep={activeStep || 0}
                    >
                        {
                            steps.map((step) => (
                                <Step key={step.label}>
                                    {loading ? <Skeleton className={classes.skeleton}/> :
                                        <StepLabel
                                            icon={step.icon}
                                        >
                                            {step.label && translate(step.label)}
                                        </StepLabel>
                                    }
                                </Step>
                            ))
                        }
                    </Stepper>
                </div>
    );
};

export default VerticalStepperCardContent;