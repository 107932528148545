import {createTheme, StyledEngineProvider, Theme, ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {createHashHistory, History} from "history";
import "isomorphic-fetch";
import {merge} from "lodash";
import React from "react";
import bccRedirectAuthClient from "../../clients/bccRedirectAuthClient";
import {CudaTheme} from "@cuda-react/theme";
import {CssBaseline} from "@barracuda-internal/bds-core";
import "@progress/kendo-theme-material/dist/all.css";
import AnalyticsProvider, {MixpanelConfig} from "../providers/AnalyticsProvider/AnalyticsProvider";
import {OneTrustProvider} from "../providers/OneTrustProvider/OneTrustProvider";
import {Router} from "react-router";
import PreviewProvider from "../providers/PreviewProvider/PreviewProvider";
import {I18nextProvider, LanguageMessages} from "../../i18n";
import {AuthClient, AuthProvider, GlobalParamsProvider} from "../providers";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

export interface CudaReactProviderProps {
    /**
     * Custom implementation of an authentication client. See [Auth Client](/?path=/docs/cudareactapp-authentication--page#auth-client) for more information.
     */
    authClient?: AuthClient,
    /**
     * Turns on BCC based authentication. See [BCC Authentication](/?path=/docs/cudareactapp-authentication--page#bcc-authentication) for more information.
     */
    bccAuthentication?: boolean,
    /**
     * Children to render within the providers context. Should be children suitable for the <Router> component.
     */
    children: any,
    /**
     * Additional i18n library messages to merge with the cuda-react i18n messages.
     */
    customMessages?: LanguageMessages,
    /**
     * Theme overrides and settings to merge with the default theme.
     */
    customTheme?: Partial<Theme>,
    /**
     * Router history to use when creating the react-router. If not provided, "createHashHistory" is used.
     */
    history?: History,
    /**
     * Configuration options for mixpanel tracking
     * if no API key is set then no tracking will occur
     *
     * @property {string} token the API token to authenticate mixpanel
     * @property {object} options any configuration options that can be set in [mixpanel.init](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit)
     * @property {function} identifier a function which accepts the globalParams object and returns a unique user identifier from it, it should return null if no tracking is desired
     */
    mixpanelConfig?: MixpanelConfig
}

/**
 * Providers for the CudaReactApp. Sets up all required providers, and the top level Router.
 *
 * For a tutorial on how to get started with CudaReactApp, see the [Getting Started](/?path=/docs/cudareactapp-getting-started-creating-an-app--creating-an-app) guide.
 */
const CudaReactProvider = ({
                               customTheme,
                               customMessages,
                               children,
                               history,
                               bccAuthentication,
                               authClient: customAuthClient,
                               mixpanelConfig
                           }: CudaReactProviderProps): JSX.Element => {
    const routerHistory = history || createHashHistory();

    const authClient = customAuthClient || (bccAuthentication ? bccRedirectAuthClient : null);

    const mergedTheme = createTheme(merge({}, CudaTheme, customTheme));

    return (
        <OneTrustProvider>
            <AnalyticsProvider mixpanelConfig={mixpanelConfig}>
                {/*TODO: Do we need this vs our own ThemeProvider?*/}
                {/*<BDSProvider useBdsTheme>*/}
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={mergedTheme}>
                        <CssBaseline/>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <GlobalParamsProvider>
                                <I18nextProvider messages={customMessages}>
                                    <Router history={routerHistory}>
                                        <AuthProvider authClient={authClient}/>
                                        <PreviewProvider>
                                            {children}
                                        </PreviewProvider>
                                    </Router>
                                </I18nextProvider>
                            </GlobalParamsProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
                {/*</BDSProvider>*/}
            </AnalyticsProvider>
        </OneTrustProvider>
    );
};

CudaReactProvider.defaultProps = {
    initialState: {}
};

export default CudaReactProvider;