import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ConnectedTableRefresh,
    CustomField,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    getDataContent,
    StatusIconField,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel,
    usePreview
} from "@cuda-react/core";
import CreateEnrollEmailDialog from "./enroll/CreateEnrollEmailDialog";
import apiResources from "../../../apiResources";
import {SuccessIcon} from "../../../components/iconMapping";
import {Forward, Help} from "@barracuda-internal/bds-core/dist/Icons/Core";
import UsersMenu from "./UsersMenu";
import {BooleanIcon, GetStartedBackgroundGraphic, UsersGraphic} from "@cuda-react/icons";
import {Dialog, Skeleton} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import useDemoApi from "../../../hooks/useDemoApi";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.error.main
    }
}));

const UsersTable = () => {
    const classes = useStyles();
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [translate] = useTranslation();
    const registerAction = useMixpanel("Endpoint Users");
    const directoriesApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
    const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);
    const identityApi = useDemoApi(apiResources.identityProvider, apiResources.demoIdentityProviders);
    const hasUserDirectory = getArrayDataContent(useCrudProps(directoriesApi)[0].data).length > 0;
    const hasIdentityProvider = getArrayDataContent(useCrudProps(identityApi)[0].data).length > 0;
    const hasLocalUsers = getArrayDataContent(useCrudProps(usersApi)[0].data).length > 0;
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const {push} = useHistory();
    const optionsChoices = [{key: "true", name: translate("tesseract.users.table.on")}, {
        key: "false",
        name: translate("tesseract.users.table.off")
    }];
    const enrollmentUrl = getDataContent(useCrudProps(apiResources.enrollmentUrl)[0].data);
    const [refreshRate, setRefreshRate] = useState<number | undefined>(undefined);
    const tpmEnforcement = usePreview("tpm");

    const handleCreateSuccess = () => {
        tableRefreshRef.current?.();
        setRefreshRate(5000);
        setTimeout(() => setRefreshRate(undefined), 11000);
    };

    return (
        <>
            <TablePage
                titleActions={actionVisible &&
                    <DisableableButton
                        buttonText="tesseract.users.dialog.buttonText"
                        disabled= {!hasIdentityProvider || !(hasUserDirectory || hasLocalUsers)}
                        disabledMessage="tesseract.users.dialog.disabledButton"
                        onClick={() => setCreateOpen(true)}
                    />
                }
                params={(params) => ({
                    ...params,
                    sort: {
                        order: params?.sort?.order,
                        field: params?.sort?.field === "enrolled" ? "deviceCount" : params?.sort?.field
                    }
                })}
                authenticated
                title="tesseract.endpoint.pageTitle"
                subtitle="tesseract.endpoint.users"
                resource={apiResources.zeroTrustUsers}
                minCellWidth={90}
                resetOnGlobalParamChange
                refreshRef={tableRefreshRef}
                exportResource={{resource: apiResources.zeroTrustUsersExport, filename: "users"}}
                onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
                pollInterval={refreshRate}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<UsersGraphic/>}
                        iconHeader="tesseract.users.noData.iconHeader"
                        textHeader="tesseract.users.noData.textHeader"
                        text="tesseract.users.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.users.noData.labelButton"
                                disabled= {!hasIdentityProvider || !(hasUserDirectory || hasLocalUsers)}
                                disabledMessage="tesseract.users.dialog.disabledButton"
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                        actionSteps={[{
                            title: "tesseract.users.noData.firstTitle",
                            text: "tesseract.users.noData.firstStep"
                        }, {title: "tesseract.users.noData.secondTitle", text: "tesseract.users.noData.secondStep"}]}
                        textFooter="tesseract.users.noData.textFooter"
                    />
                }
                resizable
                editColumns
                tableName="Users-Table"
                pageMode="paginate"
            >
                <CustomField
                    source="fullName"
                    label="tesseract.users.table.fullName"
                    filter="text"
                    sortable
                    width={90}
                    render={(value) => value ? value : <Skeleton style={{width: 90, height: 30}}/>}
                />
                <TextField
                    source="email"
                    label="tesseract.users.table.email"
                    filter="text"
                    width={120}
                    sortable
                />
                <StatusIconField
                    source="optionsOverride"
                    label="tesseract.users.table.optionsOverride"
                    icon={<BooleanIcon/>}
                    width={90}
                    filter="select"
                    filterProps={{
                        choices: optionsChoices
                    }}
                    columnProps={{
                        field: "optionsOverride"
                    }}
                    sortable
                    hidden
                />
                <StatusIconField
                    source="tamperProof"
                    label="tesseract.users.table.tamperProof"
                    icon={<BooleanIcon/>}
                    width={90}
                    sortable
                />
                <StatusIconField
                    source="devicePreConnect"
                    label="tesseract.users.table.devicePreConnect"
                    icon={<BooleanIcon/>}
                    width={110}
                    sortable
                />
                <StatusIconField
                    source="webFilter"
                    label="tesseract.users.table.webFiltering"
                    icon={<BooleanIcon/>}
                    width={90}
                    sortable
                />
                {tpmEnforcement ?
                    <StatusIconField
                        source="tpmEnforcement"
                        label="tesseract.users.table.tpmEnforcement"
                        icon={<BooleanIcon/>}
                        width={90}
                        sortable
                    /> : null
                }
                <CustomField
                    source="deviceCount"
                    label="tesseract.users.table.deviceCount"
                    render={(value, data) => {
                        const text = `${value} (${data.deviceLimit})`;
                        return data &&
                            <TextField source="text"
                                       data={{data, text}}
                                       classes={data?.deviceLimit < value ? classes : undefined}
                            />;
                    }}
                    width={90}
                    sortable
                />
                <CustomField
                    source="enrolled"
                    label="tesseract.users.table.enrollmentCompleted.label"
                    render={(value, data) => data && (data?.deviceCount > 0 ? (
                        <StatusIconField icon={<SuccessIcon/>} tooltip="tesseract.users.table.enrollmentCompleted.yes"/>
                    ) : (
                        <StatusIconField icon={<Help/>} tooltip="tesseract.users.table.enrollmentCompleted.no"/>
                    ))}
                    width={120}
                    sortable
                />
                <ActionButtonsField
                    source="id"
                    width={120}
                    alwaysVisible
                >
                    <UsersMenu enrollmentUrl={enrollmentUrl}/>
                    <DeleteDialog
                        resource={apiResources.zeroTrustUsers}
                        title="tesseract.users.delete.title"
                        message="tesseract.users.delete.body"
                        onSuccess={() => {
                            registerAction("Unenroll User");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <Forward
                        id="user-details-icon"
                        onClick={(event: any, data: any) => {
                            push("/ztna/users/" + data.id);
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                disableEnforceFocus
                onClose={() => setCreateOpen(false)}
            >
                <CreateEnrollEmailDialog
                    create
                    onSuccess={handleCreateSuccess}
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default UsersTable;