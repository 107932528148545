import React from "react";
import {makeStyles} from "@mui/styles";
import {BaseCard, BaseCardProps} from "@stratos/reporting";
import {VerticalStepperCardContent} from "@cuda-react/core";
import {useZtnaWizardState} from "../../../../hooks/StateWizardHooks";
import {Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {CircularProgress} from "@barracuda-internal/bds-core";

export interface ZTNASetupCardProps extends Omit<BaseCardProps, "children"> {
}

const useStyles = makeStyles((theme) => ({
    successIcon: {
        color: theme.palette.success.main,
        width: "22px",
        height: "22px",
        marginLeft: "-2px"
    },
    successIconLarge: {
        color: theme.palette.success.main,
        width: "30px",
        height: "30px",
        marginLeft: "-2px"
    }
}));

export const ZTNASetupCard = (props: ZTNASetupCardProps) => {
    const classes = useStyles(props);
    const {preview, size} = props;
    const {step1, step2, step3, step4, step5} = useZtnaWizardState();

    const getStepIcon = (state: string) => {
        if (state === "Completed") {
            return <Success id="step-completed-state-icon" className={size === "large" ? classes.successIconLarge : classes.successIcon}/>;
        } else if (state === "Pending") {
            return <CircularProgress size={size === "large" ? 25 : 20}/>;
        }
        return undefined;
    };

    const steps = [
        {label: "tesseract.dashboard.cards.ztnaSetup.step1", state: step1?.state, icon: getStepIcon(step1?.state)},
        {label: "tesseract.dashboard.cards.ztnaSetup.step2", state: step2?.state, icon: getStepIcon(step2?.state)},
        {label: "tesseract.dashboard.cards.ztnaSetup.step3", state: step3?.state, icon: getStepIcon(step3?.state)},
        {label: "tesseract.dashboard.cards.ztnaSetup.step4", state: step4?.state, icon: getStepIcon(step4?.state)},
        {label: "tesseract.dashboard.cards.ztnaSetup.step5", state: step5?.state, icon: getStepIcon(step5?.state)}
    ];

    // @ts-ignore
    const activeStep = steps.findLastIndex((step) => step.state === "Completed") + 1;
    const isDataLoading = step1?.loading || step2?.loading || step3?.loading || step4?.loading || step5?.loading;

    return (
        <BaseCard
            id="ZTNASetupCard"
            title="tesseract.dashboard.cards.ztnaSetup.title"
            size={props.size}
            preview={preview}
        >
            <VerticalStepperCardContent
                steps={steps}
                //@ts-ignore
                size={props.size}
                activeStep={activeStep >= steps.length ? -1 : activeStep}
                loading={isDataLoading}
            />
        </BaseCard>
    );
};

export default ZTNASetupCard;