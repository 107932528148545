import {useCrudProps, useCrudSubscription, CrudTypes, useGlobalParam} from "@cuda-react/core";
import apiResources from "../apiResources";

export enum ConnectionState {
    Completed = "Completed",
    Pending = "Pending",
    Incomplete = "Incomplete"
}

/**
 *
 * @param pointsOfEntry
 * @param gateways
 * @param sites
 *
 * @return step1state ConnectionState
 */
const getStep1State = (pointsOfEntry: [], gateways: {uuid: string, connectionStatus: string}[], sites: {uuid: string, connectionStatus: any}[]) => {
    let states: {key: string, value: string}[] = [];
    let step1State = ConnectionState.Incomplete;

    if (pointsOfEntry?.length > 0) {
        pointsOfEntry.forEach((poe: any) => {
            const appliances = poe?.configurationType === "gateway" ? gateways : sites;
            const poeAppliance = appliances && appliances.filter((appliance: any) => appliance.uuid === poe.configurationUuid);

            poeAppliance && states.push({key: poeAppliance[0]?.uuid, value: poeAppliance[0]?.connectionStatus?.aggregateConnectionState});
        });

        step1State = states.some((state) => state.value === "online") ? ConnectionState.Completed : ConnectionState.Pending;
    }
    return step1State;
};

/**
 *
 * @param users
 * @param identityProvider
 * @param userDirectories
 *
 * @return step2state ConnectionState
 */
const getStep2State = (users: [], identityProvider: {}[], userDirectories: {}[]) => {
    let step2State = ConnectionState.Incomplete;

    if (users?.length > 0) {
        step2State = ConnectionState.Completed;
    } else {
        const identityProviderStatusArray = identityProvider?.filter((provider: any) => (provider.status === "active"))?.length > 0 && ConnectionState.Completed;
        const userDirectoriesStatusArray = (userDirectories?.filter((directory: any) => (directory.syncStatus === "completed"))?.length > 0 ? ConnectionState.Completed :
            userDirectories?.filter((directory: any) => (directory.syncStatus === "pending" && !directory.lastSuccessfulSync))?. length > 0 ? ConnectionState.Pending : ConnectionState.Incomplete);

        if (identityProviderStatusArray === ConnectionState.Completed && userDirectoriesStatusArray === ConnectionState.Pending) {
            step2State = ConnectionState.Pending;
        } else if (identityProviderStatusArray === ConnectionState.Completed && userDirectoriesStatusArray === ConnectionState.Completed) {
            step2State = ConnectionState.Completed;
        }
    }

    return step2State;
};

/**
 * Hook for managing the ZTNA Setup Wizard
 * @return {step, value} an object containing the stepId string and as value an object containing a status string value and the loading boolean. The state can be completed, uncompleted and pending.
 */
export const useZtnaWizardState = () => {
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";

    // Step1
    const [pointsOfEntry , pointsOfEntryLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["pointsOfEntry"] : undefined) ?? [undefined,false];
    const [gateways, gatewaysLoading] = useCrudProps(!isDemo ? apiResources["gateways"] : undefined) ?? [undefined,false];
    const [sites, sitesLoading] = useCrudProps(!isDemo ? apiResources["sites"] : undefined) ?? [undefined,false];

    // Step2
    const [users, usersLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["users"] : undefined) ?? [undefined,false];
    const [identityProvider, identityProviderLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["identityProvider"] : undefined) ?? [undefined,false];
    const [userDirectories, userDirectoriesLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["directories"] : undefined) ?? [undefined,false];

    // Step3
    const [appCatalog, appCatalogLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["appCatalog"] : undefined) ?? [undefined,false];

    // Step4
    const [zeroTrustPolicies, zeroTrustPoliciesLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["zeroTrustPolicies"] : undefined) ?? [undefined,false];

    // Step5
    const [zeroTrustUsers, zeroTrustUsersLoading] = useCrudSubscription(CrudTypes.GET, !isDemo ? apiResources["zeroTrustUsers"] : undefined) ?? [undefined,false];

    return {
        step1: {
            state: !isDemo ? getStep1State(pointsOfEntry?.data?.content, gateways?.data?.content, sites?.data?.content) : ConnectionState.Completed,
            loading: pointsOfEntryLoading || gatewaysLoading || sitesLoading
        },
        step2: {
            state: !isDemo ? getStep2State(users?.data?.content, identityProvider?.data?.content, userDirectories?.data?.content) : ConnectionState.Completed,
            loading: usersLoading || identityProviderLoading || userDirectoriesLoading
        },
        step3: {
            state: !isDemo ? appCatalog?.data?.content?.length > 0 ? ConnectionState.Completed : ConnectionState.Incomplete : ConnectionState.Completed,
            loading: appCatalogLoading
        },
        step4: {
            state: !isDemo ? (zeroTrustPolicies?.data?.content?.filter((policy: any) => policy?.resourceCriteria?.applications?.length > 0)?.length > 0 ? ConnectionState.Completed : ConnectionState.Incomplete) : ConnectionState.Completed,
            loading: zeroTrustPoliciesLoading
        },
        step5: {
            state: !isDemo ? zeroTrustUsers?.data?.content?.length > 0 ? ConnectionState.Completed : ConnectionState.Incomplete : ConnectionState.Completed,
            loading: zeroTrustUsersLoading
        }
    };
};