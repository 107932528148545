import {
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useCrudProps,
    useDeepCompareEffect,
    usePostNotification,
} from "@cuda-react/core";
import apiResources from "../apiResources";

export default () => {
    const postNotification = usePostNotification();
    const bandwidthData = useCrudProps(apiResources.managedBandwidthsUsage)[0]?.data;

    useDeepCompareEffect(() => {
        bandwidthData?.available < 0 && postNotification({
            event: NotificationEventType.SHOW,
            display: NotificationDisplayType.BANNER,
            params: {
                content: "tesseract.statusBanner.pamBandwidthCapacity",
                level: NotificationLevel.ERROR
            }
        });
    }, [bandwidthData]);
};