import {
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select as BdsSelect,
    SelectChangeEvent,
    SelectWrapper
} from "@barracuda-internal/bds-core";
import {get, isEmpty, merge, set} from "lodash";
import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {formatErrorMessage} from "../../../utils";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Info} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const CUDA_SELECT_EMPTY_VALUE = "CUDA_SELECT_EMPTY_VALUE";

export interface BaseSelectProps {
    /**
     * if true, no option selected can be left.
     */
    allowEmpty?: boolean,
    /**
     * array of choices to select from
     */
    choices?: object[],
    /**
     * the choice to select instead of the first available choice when input.value is empty.
     */
    defaultChoice?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * if allowEmpty prop not falsy, empty choice will display this label.
     */
    emptyLabel?: string,
    /**
     * error message to display.
     */
    error?: any,
    /**
     * callback function passed to the Array.filter action. Used to locally filter the available choices.
     *
     * @function
     * @params {object} choice the choice
     * @returns {boolean} if true, choice is kept, if false, choice is filtered out and is not avaialble to select.
     */
    filterChoices?: (choice: any, index: number, initialValue?: any) => boolean,
    /**
     * icons to display on each choice.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each option.
     */
    iconMap?: { [key: string]: React.ReactNode },
    /**
     * used to generate a unique ID for the input with the format 'select-label-id-{id}'
     */
    id: string,
    /**
     * label of the Input.
     */
    label?: string,
    /**
     * @function onBlur callback to called when component stops being interacted with.
     */
    onBlur?: () => void,
    /**
     * @function onChange callback to call when input's value wants to be updated.
     * @param {object} choice the currently selected choice.
     */
    onChange?: (event: SelectChangeEvent) => void,
    /**
     * @function onFocus callback to called when input get focus.
     */
    onFocus?: () => void,
    /**
     * a dot-notation path to the categories of the choice objects.
     * Items will be displayed clubbed together under different categories.
     */
    optionCategory?: string,
    /**
     * a dot-notation path to the description in the choice objects.
     * An extra tooltip component will be render with the selected help text.
     */
    optionHelp?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be displayed in the label of each choice.
     *
     * Can also be provided as a function, that is called with the currently selected choice.
     *
     * @function
     * @param {object} choice the currently selected choice.
     * @returns {string} the text to display.
     */
    optionText?: string | React.ReactNode | ((choice: any) => string | React.ReactNode),
    /**
     * a dot-notation path to the value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    optionValue?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be used as disabled value
     */
    optionDisabled?: string,
    /**
     * optionally sort the provided options by a display name (post translation). only works
     * for plain string names.
     */
    sortByDisplayName?: boolean
    /**
     * current value of the input.
     */
    value?: any
}

const styles = (theme: Theme) => createStyles<string, BaseSelectProps>({
    formControl: {
        width: 256
    },
    listItemIcon: {
        display: "inline-flex",
        minWidth: "unset",
        marginRight: theme.spacing(1),
        verticalAlign: "middle",
        opacity: (props) => props.disabled ? 0.4 : 1,
    },
    inset: {
        paddingLeft: 32
    },
    listItemText: {
        display: "inline-flex",
        margin: 0,
        verticalAlign: "middle",
        maxWidth: "100%",
        "& span": {
            color: "currentColor",
        }
    },
    listItemTextIndented: {
        display: "inline-flex",
        margin: 0,
        verticalAlign: "middle",
        maxWidth: "100%",
        "& span": {
            color: "currentColor",
        },
        textIndent: "10px"
    },
    listItemCategory: {
        display: "inline-flex",
        margin: 0,
        verticalAlign: "middle",
        maxWidth: "100%",
        "& span": {
            color: "currentColor",
        }
    },
    listItemTextTypography: {
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    listItemCategoryTextTypography: {
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
        color: theme.palette.text.secondary
    },
    select: {
        width: 256,
    },
    selectInput: {
        overflow: "visible"
    },
    currentSelection: {
        "& span": {
            color: "blue",
        }
    }
});
const useStyles = makeOverrideableStyles("Select", styles);

export interface SelectProps extends StyledComponentProps<typeof styles>, BaseSelectProps {
}


/**
 * It renders a select dropdown list input. It uses Select and SelectWrapper components from BDS.
 * It allows to have an empty option to choose. You can set a label for that empty option with the emptyLabel prop.
 * This component is mainly used by SelectInput component which basically wraps this component with an [Input](/?path=/docs/core-components-inputs-input) component.
 */
export const Select = (props: SelectProps) => {
    const {
        allowEmpty = false,
        choices = [],
        emptyLabel,
        error,
        disabled,
        icon,
        iconMap = {},
        filterChoices,
        id,
        label,
        optionCategory = "",
        optionHelp,
        optionText = "name",
        optionValue = "key",
        optionDisabled = 'disabled',
        onBlur,
        defaultChoice,
        onChange,
        onFocus,
        value = "",
        sortByDisplayName
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const emptyOption = {};
    set(emptyOption, optionValue, CUDA_SELECT_EMPTY_VALUE);
    typeof optionText === "string" && set(emptyOption, optionText as string, emptyLabel || "cuda.inputs.select.empty");
    const allChoices = allowEmpty ? [...choices, emptyOption] : choices;
    const initialValue = useRef(value);
    const filteredChoices = filterChoices ? allChoices.filter((choice) => filterChoices(choice, value, initialValue.current)) : allChoices;
    const categories = optionCategory !== "" ? [...new Set(filteredChoices.map((choice) => get(choice, optionCategory)))] : undefined;
    let uniqueTranslations = new Set();
    const uniqueCategories: string[] = [];

    categories?.forEach((category) => {
        let translatedCategory = translate(category);
        if (!uniqueTranslations.has(translatedCategory)) {
            uniqueTranslations.add(translatedCategory);
            uniqueCategories.push(category);
        }
    });

    const getDisplayName = (choice: any) => {
        let displayName: String | React.ReactNode = "";
        if (React.isValidElement(optionText)) {
            displayName = React.cloneElement<any>(optionText, {record: choice});
        } else if (typeof optionText === "function") {
            displayName = optionText(choice);
        } else if (typeof optionText === "string") {
            displayName = get(choice, optionText);
        }
        return typeof displayName === "string" ? translate(displayName) : displayName;
    };

    if (sortByDisplayName) {
        filteredChoices.sort((choiceA, choiceB) => {
            const nameA = getDisplayName(choiceA);
            const nameB = getDisplayName(choiceB);
            if (typeof nameA === "string" && typeof nameB === "string") {
                if (nameA > nameB) {
                    return 1;
                }
                return nameB > nameA ? -1 : 0;
            }
            return 0;
        });
        uniqueCategories?.sort((choiceA: string, choiceB: string) => {
            const nameA = translate(choiceA);
            const nameB = translate(choiceB);
            if (nameA > nameB) {
                return 1;
            } else {
                return nameB > nameA ? -1 : 0;
            }
        });
    }

    useEffect(() => {
        if (!isEmpty(filteredChoices) && !allowEmpty && !filteredChoices.find((choice) => get(choice, optionValue) === value)) {
            const firstChoice = get(categories ? filteredChoices.find((choice) => get(choice, optionCategory) === categories[0]) : filteredChoices[0], optionValue);
            onChange?.(defaultChoice || firstChoice);
        }
    }, [choices, allowEmpty, value, onChange]);

    const getCurrentChoice = () => value && allChoices.find((choice) => get(choice, optionValue) === value) || {};

    const renderSelectableMenuItem = (choice: any) => {
        const displayName = getDisplayName(choice);
        const listIcon = iconMap[get(choice, optionValue)] || icon;
        return (
            <MenuItem
                key={get(choice, optionValue)}
                value={get(choice, optionValue)}
                disabled={get(choice, optionDisabled)}
            >
                {listIcon && (
                    <ListItemIcon className={classes.listItemIcon}>
                        {listIcon}
                    </ListItemIcon>
                )}
                {typeof displayName === "string" ? (
                    <ListItemText
                        primary={displayName}
                        className={categories ? classes.listItemTextIndented : classes.listItemText}
                        classes={{inset: classes.inset}}
                        inset={!isEmpty(iconMap) && !listIcon}
                        primaryTypographyProps={{className: classes.listItemTextTypography}}
                    />
                ) : displayName}
            </MenuItem>
        );
    };

    const renderCategory = (category: string) => (
        <MenuItem
            key={category.split(".").pop()}
            value={category}
        >
            <ListItemText
                primary={translate(category)}
                className={classes.listItemCategory}
                primaryTypographyProps={{className: classes.listItemCategoryTextTypography}}
            />
        </MenuItem>
    );

    const handleChange = (event: SelectChangeEvent) => {
        const newValue = event?.target?.value;
        if (categories && categories.includes(newValue)) {
            return null;
        }
        if (allowEmpty && newValue === get(emptyOption, optionValue)) {
            onChange?.(merge({}, event, {target: {value: ""}}));
        } else {
            onChange?.(event);
        }
        onBlur?.();
    };

    return filteredChoices && filteredChoices.length > 0 ? (
        <React.Fragment>
            <SelectWrapper size="small" className={classes.formControl}>
                {label && (
                    <InputLabel
                        id={"select-label-id-" + id}
                        className={classes.inputLabel}
                    >
                        {translate(label)}
                    </InputLabel>
                )}
                <BdsSelect
                    value={allowEmpty ? (value || get(emptyOption, optionValue)) : value}
                    // @ts-ignore these TS are complaining as the prop onChange has its generic type set to unknown... not sure how to fix that error
                    onChange={handleChange as any}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    inputProps={{className: classes.selectInput}}
                    id={"select-input-" + id}
                    labelId={"select-label-id-" + id}
                    label={label && translate(label)}
                    className={classes.select}
                    fullWidth
                    disabled={disabled}
                    error={!!error}
                    variant="outlined"
                >
                    {categories ?
                        uniqueCategories.map((category) => {
                            let choicesFilteredByCategory = filteredChoices.filter((choice) => translate(get(choice, optionCategory)) === translate(category));
                            return [renderCategory(category), choicesFilteredByCategory.map((choice) => renderSelectableMenuItem(choice))];
                        }) :
                        filteredChoices.map((choice) => renderSelectableMenuItem(choice))
                    }
                </BdsSelect>
                {error ? (<FormHelperText error>{formatErrorMessage(error)}</FormHelperText>) : null}
            </SelectWrapper>
            {optionHelp && getCurrentChoice()[optionHelp] && (
                <div className={classes.inputHelp}>
                    <Info tooltip={getCurrentChoice()[optionHelp]} tooltipPosition="top-left"/>
                </div>
            )}
        </React.Fragment>
    ) : null;
};

export default Select;