import {get} from "lodash";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    BooleanInput,
    FormSection,
    getArrayDataContent,
    getDataContent,
    Input,
    SelectArrayInput,
    SelectInput,
    SelectPairArrayInput,
    TextArrayInput,
    useCrudProps,
    useTableChoices
} from "@cuda-react/core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
// @ts-ignore
import {categoryIcons} from "../components/iconMapping";
import {CustomAppsIcon, GatewayIcon, SiteIcon} from "@cuda-react/icons";
import {CircularProgress} from "@barracuda-internal/bds-core";
import apiResources from "../apiResources";
import useDemoApi from "./useDemoApi";

// interface SourceTypeChoice {
//     key: any;
//     name: any;
// }

const sourceTypeChoices = [
    {key: "application", name: "tesseract.security.sourceCriteria.application"},
    {key: "internet", name: "tesseract.security.sourceCriteria.internet"},
    {key: "network", name: "tesseract.security.sourceCriteria.network"},
    {key: "networks", name: "tesseract.security.sourceCriteria.network"},
    {key: "onPremGateway", name: "tesseract.security.sourceCriteria.onpremGateway"},
    {key: "site", name: "tesseract.security.sourceCriteria.site"},
    {key: "userOrGroup", name: "tesseract.security.sourceCriteria.userOrGroup"}
];

export const getSourceTypeChoices = (types: string[]) => sourceTypeChoices.filter((choice) => types.includes(choice.key));

type HideInputFunction = (key: string, type: string) => (value: any, data: any) => boolean;
const hideInput: HideInputFunction = (key: string, type: string) => (value: string, data: any) => !get(data, key) || get(data, key) !== type;
export const UserGroupDisabledMessage = () => {
    const [translate] = useTranslation();

    return (
        <FormHelperText error>
            {translate("tesseract.security.sourceCriteria.userOrGroupDisabled")}
        </FormHelperText>
    );
};

/**
 * Function that returns the components needed to setup a source criteria form section.
 * This is not a react component and must be called from within, its return value use as part of, a component's render method.
 *
 * @param sourceTypes - string array of the source input types to render, e.g. ["network", "site"];
 * @returns {JSX.Element}
 */
export const useGeneratedSourceCriteriaSection = (sourceTypes: string[]) => {
    const sourceSelectChoices = getSourceTypeChoices(sourceTypes);
    const userOrGroupEnabled = useCrudProps(sourceTypes.includes("userOrGroup") && apiResources.userOrGroupEnabled || undefined)[0]?.data;
    const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);
    const hasLocalUser = getArrayDataContent(useCrudProps(usersApi)[0].data).length > 0;
    const userOrGroupDisabled = !hasLocalUser && (getDataContent(userOrGroupEnabled) !== true);

    return (
        <FormSection title="tesseract.security.sourceCriteria.section">
            <SelectInput
                source="source.type"
                description="tesseract.security.sourceCriteria.description"
                label="tesseract.security.sourceCriteria.type"
                choices={sourceSelectChoices}
                isRequired
            />
            {sourceTypes.includes("application") && (
                <SelectArrayInput
                    source="source.applications"
                    label="tesseract.security.sourceCriteria.applications"
                    description="tesseract.security.sourceCriteria.applicationDescription"
                    resource={apiResources.customApps}
                    optionValue="applicationId"
                    options={{
                        params: {filter: {type: "network"}},
                        dropdownAutoWidth: true
                    }}
                    hide={hideInput("source.type", "application")}
                    icon={<CustomAppsIcon/>}
                    isRequired
                />
            ) || null}
            {sourceTypes.includes("network") && (
                <TextArrayInput
                    source="source.networks"
                    label="tesseract.security.sourceCriteria.network"
                    description="tesseract.security.sourceCriteria.networkDescription"
                    hide={hideInput("source.type", "network")}
                    isRequired
                />
            ) || null}
            {sourceTypes.includes("networks") && (
                <TextArrayInput
                    source="source.networks"
                    label="tesseract.security.sourceCriteria.network"
                    description="tesseract.security.sourceCriteria.networkDescription"
                    hide={hideInput("source.type", "networks")}
                    isRequired
                />
            ) || null}
            {sourceTypes.includes("onPremGateway") && (
                <BooleanInput
                    source="source.allGateways"
                    label="tesseract.security.sourceCriteria.allOnpremGateways"
                    hide={hideInput("source.type", "onPremGateway")}
                />
            ) || null}
            {sourceTypes.includes("onPremGateway") && (
                <SelectPairArrayInput
                    source="source.gateways"
                    label="tesseract.security.sourceCriteria.onpremGateway"
                    description="tesseract.security.sourceCriteria.onpremGatewayDescription"
                    primarySource="id"
                    primaryLabel="tesseract.security.sourceCriteria.onpremGateway"
                    primaryOptionValue="uuid"
                    resource={apiResources.gatewaysOnPremNetworks}
                    addTitle="tesseract.security.sourceCriteria.addOnpremGateway"
                    editTitle="tesseract.security.sourceCriteria.editOnpremGateway"
                    secondarySource="networks"
                    secondaryChoices="networks"
                    secondaryOptions={{
                        options: {
                            maxOptionsVisible: 2
                        }
                    }}
                    secondaryOptionValue="id"
                    secondarySelectAll
                    secondarySelectAllText="tesseract.security.sourceCriteria.allNetworks"
                    secondaryLabel="tesseract.security.sourceCriteria.gatewayNetwork"
                    hide={(value, data) => hideInput("source.type", "onPremGateway")(value, data) || get(data, "source.allGateways")}
                    isRequired
                />
            ) || null}
            {sourceTypes.includes("site") && (
                <BooleanInput
                    source="source.allSites"
                    label="tesseract.security.sourceCriteria.allSites"
                    hide={hideInput("source.type", "site")}
                />
            ) || null}
            {sourceTypes.includes("site") && (
                <SelectPairArrayInput
                    source="source.sites"
                    label="tesseract.security.sourceCriteria.site"
                    description="tesseract.security.sourceCriteria.siteDescription"
                    primarySource="id"
                    primaryLabel="tesseract.security.sourceCriteria.site"
                    primaryOptionValue="uuid"
                    resource={apiResources.siteNetworks}
                    addTitle="tesseract.security.sourceCriteria.addSite"
                    editTitle="tesseract.security.sourceCriteria.editSite"
                    secondarySource="networks"
                    secondaryChoices="networks"
                    secondaryOptions={{
                        options: {
                            maxOptionsVisible: 2
                        }
                    }}
                    secondaryOptionValue="id"
                    secondarySelectAll
                    secondarySelectAllText="tesseract.security.sourceCriteria.allNetworks"
                    secondaryLabel="tesseract.security.sourceCriteria.siteNetwork"
                    hide={(value, data) => hideInput("source.type", "site")(value, data) || get(data, "source.allSites")}
                    isRequired
                />
            ) || null}
            {sourceTypes.includes("userOrGroup") && (
                <SelectArrayInput
                    source="source.users"
                    label="tesseract.security.sourceCriteria.users"
                    description="tesseract.security.sourceCriteria.userDescription"
                    resource={apiResources.users}
                    optionValue="name"
                    require={(value, data) => !(get(data, "source.groups") || []).length}
                    hide={hideInput("source.type", "userOrGroup")}
                    options={{disabled: userOrGroupDisabled, dropdownAutoWidth: true}}
                    icon={<User/>}
                />
            )}
            {sourceTypes.includes("userOrGroup") && (
                <SelectArrayInput
                    source="source.groups"
                    label="tesseract.security.sourceCriteria.groups"
                    description="tesseract.security.sourceCriteria.groupDescription"
                    resource={apiResources.groups}
                    optionValue="id"
                    require={(value, data) => !(get(data, "source.users") || []).length}
                    hide={hideInput("source.type", "userOrGroup")}
                    options={{disabled: userOrGroupDisabled, dropdownAutoWidth: true}}
                    icon={<UserGroup/>}
                />
            )}
            {sourceTypes.includes("userOrGroup") && userOrGroupDisabled && (
                <Input
                    source="userOrGroupDisabled"
                    minimised
                    inputLabelProps={{fullWidth: true}}
                    component={UserGroupDisabledMessage}
                    hide={hideInput("source.type", "userOrGroup")}
                />
            )}
        </FormSection>
    );
};

// eslint-disable-next-line react/display-name
const generateChipArrayProps = (renderers: Record<string, any>, categoryChoices: string[]) => (target: string) => (chipArrayProps: any) => {
    const {
        renderSiteNetwork,
        renderGatewayNetwork,
        renderApplication,
        getApplicationIconId,
        renderCustomCategory,
        renderGroup,
        renderGateway,
        renderGatewayName,
        renderGatewayWan,
        renderSiteName,
        renderSiteWan,
        renderTargetApplication,
        getTargetApplicationIconId,
        renderUser
    } = renderers;
    const type = get(chipArrayProps, `data.${target}.type`) || "network";

    if (type === "site" && get(chipArrayProps, `data.${target}.allSites`)) {
        return {
            ...chipArrayProps,
            source: `${target}.allSites`,
            choices: [{key: true, name: "tesseract.security.tables.allSites"}],
            icon: <SiteIcon/>
        };
    }

    if (type === "site" && get(chipArrayProps, `data.${target}.wan`)) {
        return {
            ...chipArrayProps,
            source: `destination.applianceId`,
            render: renderSiteWan,
            icon: <SiteIcon/>
        };
    }

    if (type === "site") {
        return {
            ...chipArrayProps,
            source: `${target}.sites`,
            render: renderSiteNetwork,
            optionValue: "uuid",
            sourceValue: "id",
            icon: <SingleSiteIcon/>
        };
    }

    if (type === "onPremGateway" && get(chipArrayProps, `data.${target}.allGateways`)) {
        return {
            ...chipArrayProps,
            source: `${target}.allGateways`,
            choices: [{key: true, name: "tesseract.security.tables.allOnpremGateways"}],
            icon: <GatewayIcon/>
        };
    }

    if (type === "onPremGateway" && get(chipArrayProps, `data.${target}.allGateways`) === false && get(chipArrayProps, `data.${target}.gateways`)) {
        return {
            ...chipArrayProps,
            source: `${target}.gateways`,
            render: renderGatewayNetwork,
            optionValue: "uuid",
            sourceValue: "id",
            icon: <GatewayIcon/>
        };
    }

    if (type === "application") {
        return {
            ...chipArrayProps,
            source: `${target}.applications`,
            render: renderApplication,
            optionValue: "id",
            iconMap: categoryIcons,
            optionIcon: getApplicationIconId
        };
    }

    if (target === "target") {
        return {
            ...chipArrayProps,
            source: `target`,
            render: renderTargetApplication,
            optionValue: "id",
            iconMap: categoryIcons,
            optionIcon: getTargetApplicationIconId
        };
    }

    if (type === "category") {
        return {
            ...chipArrayProps,
            source: `${target}.categories`,
            choices: categoryChoices || []
        };
    }

    if (type === "customCategory") {
        return {
            ...chipArrayProps,
            source: `destination.customCategories`,
            render: renderCustomCategory,
        };
    }

    if (type === "userOrGroup") {
        return {
            ...chipArrayProps,
            source: `${target}.userOrGroups`,
            optionValue: "name",
            iconMap: {
                user: <User/>,
                group: <UserGroup/>
            },
            optionIcon: "icon",
            render: (value: any, ...args: any[]) => (get(value, "id") ? renderGroup(get(value, "id"), ...args) : renderUser(get(value, "name"), ...args))
        };
    }

    if (type === "gateway") {
        return {
            ...chipArrayProps,
            source: `${target}.${type}s`,
            render: renderGateway,
            optionValue: "uuid",
            icon: <GatewayIcon/>
        };
    }

    if (type === "onPremGateway") {
        return {
            ...chipArrayProps,
            source: `destination.applianceId`,
            render: renderGatewayWan,
            icon: <GatewayIcon/>
        };
    }

    if (type === "onPremGatewayName") {
        return {
            ...chipArrayProps,
            source: `destination.applianceId`,
            render: renderGatewayName,
            icon: <GatewayIcon/>
        };
    }

    if (type === "siteName") {
        return {
            ...chipArrayProps,
            source: `destination.applianceId`,
            render: renderSiteName,
            icon: <SiteIcon/>
        };
    }

    if (type === "any") {
        return {
            ...chipArrayProps,
            source: `${target}.type`,
            choices: [
                {key: "any", name: "Any"}
            ],
        };
    }

    if (type === "networks") {
        return {
            ...chipArrayProps,
            source: `${target}.${type}`,
        };
    }

    if (type === "internet") {
        return {
            ...chipArrayProps,
            source: `${target}.type`,
            choices: [
                {key: "internet", name: "Internet"}
            ],
        };
    }

    return {
        ...chipArrayProps,
        source: `${target}.${type}s`
    };
};

// eslint-disable-next-line react/display-name
const generateGenericChipArrayProps = (renderers: Record<string, any>) => (target: string) => (chipArrayProps: any) => {
    const {renderGateway} = renderers;
    const type = get(chipArrayProps, `data.${target}`) || null;

    if (type === "gateway") {
        return {
            ...chipArrayProps,
            source: `configurationUuid`,
            render: renderGateway,
            icon: <GatewayIcon/>
        };
    }

    return {
        ...chipArrayProps,
    };
};

/**
 * Hook for supplying the selected choice field's props for a given table.
 *
 * @param categoryChoices
 * @returns {function(*): function(*=): ({icon: *, source: string, choices: [{name: string, key: boolean}]})}
 */
export const useSourceDestinationField = (categoryChoices: string[] = []) => {
    const renderSiteNetwork = useTableChoices(apiResources.siteNetworks, {
        optionValue: "uuid",
        filterId: "uuid",
        staticChoices: [{uuid: "allSites", name: "tesseract.security.applicationControl.settings.allSites"}],
        render: (choice, loading, value, rowData, sourceData) => {
            const selectedNetworks = get(sourceData, "networks") || [];
            const choiceNetworks = get(choice, "networks") || [];

            let renderContent = choice?.name;
            if (selectedNetworks.length) {
                renderContent = choice?.name && selectedNetworks.map((network: number | string) => {
                    const networkName = (choiceNetworks.find((item: any) => item.id === network) || {}).name;
                    return networkName ? choice.name + ": " + networkName : null;
                }).filter((item: any) => !!item);
            }
            return renderContent ? renderContent : (
                loading ?
                    <CircularProgress size={14}/> :
                    null
            );
        }
    });
    const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
        optionValue: "id",
        filterId: "applicationId"
    }, "categoryId");
    const renderGroup = useTableChoices(apiResources.groups, {optionValue: "id"});
    const renderGateway = useTableChoices(apiResources.gateways, {
        optionValue: "uuid",
        filterId: "uuid",
        staticChoices: [{uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways"}]
    });
    const renderGatewayNetwork = useTableChoices(apiResources.gatewaysOnPremNetworks, {
        optionValue: "uuid",
        filterId: "uuid",
        staticChoices: [{uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways"}],
        render: (choice, loading, value, rowData, sourceData) => {
            const selectedNetworks = get(sourceData, "networks") || [];
            const choiceNetworks = get(choice, "networks") || [];

            let renderContent = choice?.name;
            if (selectedNetworks.length) {
                renderContent = choice?.name && selectedNetworks.map((network: number | string) => {
                    const networkName = (choiceNetworks.find((item: any) => item.id === network) || {}).name;
                    return networkName ? choice.name + ": " + networkName : null;
                }).filter((item: any) => !!item);
            }
            return renderContent ? renderContent : (
                loading ?
                    <CircularProgress size={14}/> :
                    null
            );
        }
    });
    const renderGatewayWan = useTableChoices(apiResources.gatewaysOnPrem, {
        optionValue: "id",
        filterId: "id",
        staticChoices: [{uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways"}],
        // @ts-ignore Definition in cuda-react is incorrect, needs updating
        render: (choice, loading, value, rowData) => {
            const rowWanId = get(rowData, "destination.wan") || null;
            const choiceWans = get(choice, "wans") || [];

            let renderContent = "";
            if (rowWanId) {
                const wanElement = choiceWans && choiceWans.find((wan: any) => (wan.id === rowWanId));
                renderContent = wanElement?.name && (rowData.destination.port ? wanElement.name + ": " + rowData.destination.port : wanElement.name);
            }
            return renderContent ? renderContent : (
                loading ?
                    <CircularProgress size={14}/> :
                    null
            );
        }
    });
    const renderSiteWan = useTableChoices(apiResources.siteConfiguration, {
        optionValue: "id",
        // @ts-ignore Definition in cuda-react is incorrect, needs updating
        render: (choice, loading, value, rowData) => {
            const rowWanId = get(rowData, "destination.wan");
            const choiceWans = get(choice, "wans");

            let renderContent = "";
            if (rowWanId) {
                const wanElement = choiceWans?.find((wan: any) => (wan.id === rowWanId));
                renderContent = wanElement?.name && (rowData.destination.port ? wanElement.name + ": " + rowData.destination.port : wanElement.name);
            }
            return renderContent ? renderContent : (
                loading ?
                    <CircularProgress size={14}/> :
                    null
            );
        }
    });
    const renderGatewayName = useTableChoices(apiResources.gatewaysOnPrem, {
        optionValue: "id",
        filterId: "id"
    });
    const renderSiteName = useTableChoices(apiResources.sites, {
        optionValue: "id",
        filterId: "id",
    });
    const [renderTargetApplication, getTargetApplicationIconId] = useTableChoices(apiResources.validTargets, {optionValue: "key"}, "categoryId");

    const renderCustomCategory = useTableChoices(apiResources.customCategories, {
        optionValue: "id",
        filterId: "id"
    });

    const renderUser = useTableChoices(apiResources.users, {optionValue: "id"});

    return generateChipArrayProps({
        renderSiteNetwork,
        renderGatewayNetwork,
        renderApplication,
        getApplicationIconId,
        renderCustomCategory,
        renderGroup,
        renderGateway,
        renderGatewayName,
        renderGatewayWan,
        renderSiteName,
        renderSiteWan,
        renderTargetApplication,
        getTargetApplicationIconId,
        renderUser
    }, categoryChoices);
};

export const useGenericField = (categoryChoices = []) => {
    const renderGateway = useTableChoices(apiResources.gateways, {
        optionValue: "uuid",
        filterId: "uuid",
        staticChoices: [{uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways"}]
    });

    return generateGenericChipArrayProps({renderGateway});
};